<template lang="pug">
.static-header-bar(@click="expanded = false")
  .backdrop(v-if="expanded")
  .layout.flex.inner-gap
    .toggler(@click.stop="expanded = !expanded")
      i.pf-icon.bx.bx-menu
    .logo
      nuxt-link(:to="{ name: 'index' }" title="Pod Foods")
        img(src="/img/logo.svg" alt="Pod Foods" width="190" height="40")
    .menu(:class="{ expanded }" @click.stop)
      nuxt-link(:to="{ name: 'index' }" title="Home") Home

      nuxt-link(:to="{ name: 'about' }" title="About") About

      nuxt-link(:to="{ name: 'services-brands' }" title="Brands") Brands

      nuxt-link(:to="{ name: 'services-retailers' }" title="Retailers") Retailers

      nuxt-link(:to="{ name: 'products' }" title="Catalog" @click.native="$amplitude.track('CLICK_CATALOG')") Catalog

      nuxt-link(:to="{ name: 'blog' }" title="Blog" @click.native="$amplitude.track('CLICK_BLOG')") Blog

    .user
      user-box
</template>

<script>
import UserBox from './UserBox'

export default {
  components: {
    UserBox
  },

  data () {
    return {
      expanded: false
    }
  },

  head () {
    return {
      bodyAttrs: {
        class: this.expanded ? 'pf-no-scroll' : ''
      }
    }
  },

  watch: {
    $route () {
      this.expanded = false
    }
  }
}
</script>

<style lang="scss" scoped>
.static-header-bar {
  position: relative;
  background: rgba(white, .98);
  border-bottom: 1px solid #f1f1f1;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  // backdrop-filter: blur(20px);
  .backdrop {
    @include media(sm-down) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(black, .1);
      z-index: -1;
    }
  }
  .layout {
    align-items: center;
    min-height: 50px;
    @include media(md) {
      min-height: 90px;
    }
    .toggler {
      display: block;
      margin-right: .75em;
      @include media(md) {
        display: none;
      }
    }
    .logo {
      flex: 120px 0;
      padding: 10px 0;
      @include media(md) {
        flex: 220px 0;
      }
      @include media(lg) {
        flex: 310px 0;
      }
      img {
        display: block;
        width: 120px;
        @include media(md) {
          width: 160px;
        }
      }
    }
    .menu {
      @include media(sm-down) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 240px;
        background: white;
        z-index: -1;
        padding: 50px 1em 1em;
        &.expanded {
          display: block;
        }
        > a, div {
          display: block;
          padding: 1em 0;
          color: $--color-text-regular;
          border-bottom: 1px solid #eee;
          &.nuxt-link-exact-active {
            color: $--color-primary;
            font-weight: $--font-weight-bold;
          }
        }
      }
      @include media(md) {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        > a, div {
          padding: 35px 0;
          border-bottom: 2px solid transparent;
          color: $--color-text-regular;
          margin: 0 12px;
          @include media(lg) {
            margin: 0 16px;
          }
          &.nuxt-link-exact-active {
            border-bottom-color: $--color-primary;
          }
        }
        > div {
          padding: .75em 0;
        }
      }
    }
    .user {
      text-align: right;
      flex: 0 410px;
      @include media(sm-down) {
        flex: 1;
      }
    }
  }
}
</style>
